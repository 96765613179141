import "primereact/resources/themes/saga-orange/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import * as React from "react"
import {Layout} from "../containers/Layout";
import {PageProps} from "gatsby";
import styled from 'styled-components';
import {Palette} from "../constants";



export default function Index(props: PageProps) {
    return <StyledLayout {...props}>
        <Blocked id={'blocked'}>Blocked</Blocked>
    </StyledLayout>
}

const StyledLayout = styled(Layout)`
  background-color: #${Palette.MaximumYellowRed.hex};
  align-items: center;
  
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
  }
`;

const Blocked = styled.div`
  font-size: 4rem;      
`;
